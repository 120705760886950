@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
    font-family: 'vazir';
    src: url('../public/fonts/Vazir.eot');
    src: url('../public/fonts/Vazir.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/Vazir.woff2') format('woff2'), url('../public/fonts/Vazir.woff') format('woff'),
        url('../public/fonts/Vazir.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'roboto';
    src: url('../public/fonts/Roboto-Regular.eot');
    src: url('../public/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/Roboto-Regular.woff2') format('woff2'),
        url('../public/fonts/Roboto-Regular.woff') format('woff'),
        url('../public/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'vazir', sans-serif;
    /* scrollbar styles for mozilla firefox */
    scrollbar-color: #888 #f1f1f1;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    background: #1d1e2c;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
